<template>
  <div>
    <div class="col-12 px-1">
      <div class="filter p-1">
        <div class="text-light">
          <h2 class="colr">
            {{ $t('dashBoard.demandList') }}
          </h2>
        </div>
      </div>
      <!-- filter -->
      <div class="mb-2">
        <div>
          <app-collapse>
            <app-collapse-item
              :title="$t('complaints.complaint_list.filters.title')"
            >
              <b-form @submit.prevent>
                <b-row>
                  <!-- secteur_id -->
                  <b-col lg="6">
                    <b-form-group
                      :label="
                        $t(
                          'customer_information.information_content.create_input_sector'
                        )
                      "
                      label-cols-md="12"
                      label-for="mots-cles"
                    >
                      <v-select
                        id="roles"
                        v-model="filterBy.secteur_id"
                        :placeholder="
                          $t(
                            'customer_information.information_content.create_input_sector_placeholder'
                          )
                        "
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :reduce="(option) => option.id"
                        :options="listSecteurs"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- type_demande_id -->
                  <b-col lg="6">
                    <b-form-group
                      :label="$t('dashBoard.demType')"
                      label-cols-md="12"
                      label-for="mots-cles"
                    >
                      <v-select
                        v-model="filterBy.type_demande_id"
                        :placeholder="$t('dashBoard.demChoice')"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :reduce="(option) => option.id"
                        :options="listTypesDemandes"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- recherche par identity -->
                  <b-col lg="6">
                    <b-form-group
                      :label="$t('dashBoard.demresearch')"
                      label-cols-md="12"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="filterBy.identity"
                          type="text"
                          class="form-control"
                          :placeholder="$t('dashBoard.researchEx')"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <!-- periode -->
                  <b-col lg="6">
                    <b-form-group
                      label-cols-md="12"
                      :label="$t('request_manage.filter_label_period')"
                    >
                      <b-input-group>
                        <DateRangeWidget
                          v-model="filterBy.periode"
                          :range="filterBy.periode"
                          placeholder="Filtrer par date"
                          class="p-0 m-0"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <!-- statuses -->
                  <b-col lg="6">
                    <b-form-group
                      :label="$t('dashBoard.status')"
                      label-cols-md="12"
                      label-for="mots-cles"
                    >
                      <v-select
                        v-model="filterBy.statuses"
                        multiple
                        :placeholder="''"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="etape"
                        :reduce="(option) => option.status"
                        :options="statuses"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- reference -->
                  <b-col lg="6">
                    <b-form-group
                      :label="$t('dashBoard.refresearch')"
                      label-cols-md="12"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="filterBy.reference"
                          type="text"
                          class="form-control"
                          :placeholder="$t('dashBoard.refresearch')"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <!-- recherche code_demande -->
                  <b-col lg="6">
                    <b-form-group
                      :label="$t('dashBoard.coderesearch')"
                      label-cols-md="12"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="filterBy.code_demande"
                          type="text"
                          class="form-control"
                          :placeholder="$t('dashBoard.placeholder')"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <hr class="" />
                  <!-- button -->
                  <b-col lg="12">
                    <div class="d-flex flex-row-reverse nopadding">
                      <b-button
                        class="float-right"
                        variant="warning"
                        @click="getListDemandes()"
                      >
                        <feather-icon icon="SearchIcon" class="mr-25" />
                        <span>{{ $t('button.search') }}</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </app-collapse-item>
          </app-collapse>
        </div>
      </div>
      <!-- list -->
      <b-card class="mt-1">
        <div class="my-1">
          <b-table
            ref="refFormsTable"
            :items="tableRequestData"
            responsive
            :fields="tableRequestColumns"
            primary-key="id"
            show-empty
            empty-text="Aucune donnée"
            :busy="isLoading_requests"
            hover
          >
            <!-- Custom Header -->
            <template #head()="{ label, field: { key, sortable } }">
              {{ $t(label) }}
            </template>
            <!-- busy -->
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <!-- columns -->
            <template #cell(created_at)="data">
              {{ data.item.created_at }}
              <span v-if="show_alert(data.item.statuses && data.item.statuses[0] && data.item.statuses[0])" class="ml-1">
                <b-badge
                  pill
                  :variant="
                    getVariantColorByInterval(
                      time_spent_in_days(data.item.created_at && data.item.created_at),
                      data.item.duration && data.item.duration
                    )
                  "
                >
                  soumise depuis
                  {{ time_spent_in_days(data.item.created_at && data.item.created_at) }}
                  jours(s)
                </b-badge>
              </span>
            </template>
            <template #cell(identity)="data">
              <span>
                <!-- {{ data.item.identity  }}  <br> -->
                {{ data.item.user && data.item.user.profile.nom_etablissement }}
              </span>
            </template>
            <template #cell(sector_label)="data">
              <span>
                <!-- {{ data.item.identity  }}  <br> -->
                {{ data.item.sector_label }}
              </span>
            </template>
            <template #cell(statuses)="data">
              <!-- {{ data.item.statuses[0].name }} -->
              {{
                $t(statusMatching(
                  data.item.process_steps,
                  data.item.statuses && data.item.statuses[0] && data.item.statuses[0].name
                ))
              }}
            </template>
            <template #cell(type_demande)="data">
              {{
                (data.item.type_demande && data.item.type_demande.title) ||
                data.item.type_demande_id
              }}
            </template>
            <template #cell(actions)="data">
              <span class="d-flex flex-row">
                <!-- can_process_to_treatment: {{ data.item.can_process_to_treatment }} <br> -->
                <!-- should_have_auto_eval: {{ data.item.should_have_auto_eval }}<br> -->
                <!-- auto_evaluation: {{ data.item.auto_evaluation }}<br> -->
                <!-- audit: {{ data.item.audit  }} -->
                <!-- <i
                          v-b-tooltip.hover.top.v-secondary="
                            (!data.item.can_process_to_treatment &&
                              !data.item.should_have_auto_eval &&
                              !data.item.audit &&
                              $t('request_manage.no_audit')) ||
                              (!data.item.can_process_to_treatment &&
                                data.item.should_have_auto_eval &&
                                !data.item.auto_evaluation &&
                                !data.item.audit &&
                                $t('request_manage.no_audit_and_val')) ||
                              (!data.item.can_process_to_treatment &&
                                data.item.should_have_auto_eval &&
                                !data.item.auto_evaluation &&
                                $t('request_manage.no_eval')) ||
                              (!data.item.can_process_to_treatment &&
                                data.item.should_have_auto_eval &&
                                !data.item.audit &&
                                $t('request_manage.no_audit'))
                          "
                          class="las la-info iconBig my-auto mr-1"
                          :class="
                            data.item.can_process_to_treatment
                              ? 'iconGreen'
                              : 'iconRed'
                          "
                        /> -->
                <b-button
                  v-b-tooltip.hover.top="$t('request_manage.details_tooltip')"
                  variant="primary"
                  class="mx-0"
                  @click="
                    $router.push({
                      name: 'formulaires-details',
                      params: {
                        id: data.item.id,
                        slug: data.item.slug,
                      },
                    })
                  "
                >
                  <i class="las la-eye" />
                </b-button>
              </span>
            </template>
          </b-table>
          <!-- pagination -->
          <div>
            <PaginationComponent
              :table-data="tableRequestData"
              :per-page="paginationRequestData.per_page"
              :total-rows="paginationRequestData.total"
              @change="onPaginationRequestChange"
            />
          </div>
        </div>
      </b-card>
    </div>

    <!-- modal export stat -->
    <b-modal
      id="modal_export_stat"
      ref="modal_export_stat"
      hide-backdrop
      ok-only
      no-close-on-backdrop
      content-class="shadow"
      title="Aperçu"
      ok-title="Imprimer"
      size="lg"
      centered
      @ok="exportation_stat()"
    >
      <div class="row px-1">
        <!-- demandes -->
        <b-col cols="12">
          <b-card>
            <div class="d-flex align-items-center justify-content-start w-100">
              <div class="">
                <div class="icon">
                  <i
                    class="las la-folder-open"
                    style="background-color: #d9f9e1; color: #3c6e4b"
                  />
                </div>
              </div>
              <h4 class="mb-0 ml-1 text-clr">
                {{ $t('dashboard.request') }}
              </h4>
            </div>
            <b-row class="d-flex justify-content-between mx-2 mt-1">
              <span
                >Total : <strong>{{ totalRequests }}</strong>
              </span>
              <span
                >Traités : <strong>{{ donutChartRequest.series[0] }}</strong>
              </span>
              <span
                >Non Traités / En attente :
                <strong>{{ donutChartRequest.series[1] }}</strong>
              </span>
            </b-row>
          </b-card>
        </b-col>
        <!-- reclamations -->
        <b-col cols="12">
          <b-card>
            <div class="d-flex align-items-center justify-content-start w-100">
              <div class="">
                <div class="icon">
                  <i
                    class="las la-gavel"
                    style="background-color: #f9f8d5; color: #b38e38"
                  />
                </div>
              </div>
              <h4 class="mb-0 ml-1 text-clr">
                {{ $t('dashboard.claims') }}
              </h4>
            </div>
            <div class="d-flex justify-content-between mx-2 mt-1">
              <span
                >Total : <strong>{{ totalClaims }}</strong>
              </span>
              <span
                >Traités : <strong>{{ donutChartClaims.series[0] }}</strong>
              </span>
              <span
                >Non Traités / En attente :
                <strong>{{ donutChartClaims.series[1] }}</strong>
              </span>
            </div>
          </b-card>
        </b-col>
        <!-- concours -->
        <b-col cols="12">
          <b-card>
            <div class="d-flex align-items-center justify-content-start w-100">
              <div class="">
                <div class="icon">
                  <i
                    class="las la-certificate"
                    style="background-color: #ffe4e6; color: #cc6565"
                  />
                </div>
              </div>
              <h4 class="mb-0 ml-1 text-clr">
                {{ $t('dashboard.contest') }}
              </h4>
            </div>
            <b-row class="d-flex justify-content-between mx-2 mt-1">
              <span
                >Total : <strong>{{ totalContests }}</strong>
              </span>
              <span
                >Traités : <strong>{{ donutChartContest.series[0] }}</strong>
              </span>
              <span
                >Non Traités / En attente :
                <strong>{{ donutChartContest.series[1] }}</strong>
              </span>
            </b-row>
          </b-card>
        </b-col>
        <!-- users -->
        <b-col cols="12">
          <b-card>
            <div class="d-flex align-items-center justify-content-start w-100">
              <div class="">
                <div class="icon">
                  <i
                    class="las la-users"
                    style="background-color: #d4eff4; color: #4762af"
                  />
                </div>
              </div>
              <h4 class="mb-0 ml-1 text-clr">
                {{ $t('dashboard.users') }}
              </h4>
            </div>
            <b-row class="d-flex justify-content-between mx-2 mt-1">
              <span
                >Total : <strong>{{ totalUsers }}</strong>
              </span>
              <span
                >Agents Administration :
                <strong>{{ donutChartUsers.series[1] }}</strong>
              </span>
              <span
                >Opérateurs :
                <strong>{{ donutChartUsers.series[0] }}</strong>
              </span>
              <span
                >Autres utilisateurs :
                <strong>{{ donutChartUsers.series[2] }}</strong>
              </span>
            </b-row>
          </b-card>
        </b-col>
      </div>
    </b-modal>
    <!-- impression stats -->
    <vue-html2pdf
      v-show="false"
      ref="printableStat"
      class="border rounded"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="true"
      :manual-pagination="true"
      :filename="`Statistiques globales`"
      :pdf-quality="2"
      :pdf-margin="0"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
    >
      <section slot="pdf-content">
        <div class="row px-1">
          <!-- demandes -->
          <b-col cols="12">
            <b-card>
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <div class="">
                  <div class="icon">
                    <i
                      class="las la-folder-open"
                      style="background-color: #d9f9e1; color: #3c6e4b"
                    />
                  </div>
                </div>
                <h4 class="mb-0 ml-1 text-clr">
                  {{ $t('dashboard.request') }}
                </h4>
              </div>
              <b-row class="d-flex justify-content-between mx-2 mt-1">
                <span
                  >Total : <strong>{{ totalRequests }}</strong>
                </span>
                <span
                  >Traités : <strong>{{ donutChartRequest.series[0] }}</strong>
                </span>
                <span
                  >Non Traités / En attente :
                  <strong>{{ donutChartRequest.series[1] }}</strong>
                </span>
              </b-row>
            </b-card>
          </b-col>
          <!-- reclamations -->
          <b-col cols="12">
            <b-card>
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <div class="">
                  <div class="icon">
                    <i
                      class="las la-gavel"
                      style="background-color: #f9f8d5; color: #b38e38"
                    />
                  </div>
                </div>
                <h4 class="mb-0 ml-1 text-clr">
                  {{ $t('dashboard.claims') }}
                </h4>
              </div>
              <div class="d-flex justify-content-between mx-2 mt-1">
                <span
                  >Total : <strong>{{ totalClaims }}</strong>
                </span>
                <span
                  >Traités : <strong>{{ donutChartClaims.series[0] }}</strong>
                </span>
                <span
                  >Non Traités / En attente :
                  <strong>{{ donutChartClaims.series[1] }}</strong>
                </span>
              </div>
            </b-card>
          </b-col>
          <!-- concours -->
          <b-col cols="12">
            <b-card>
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <div class="">
                  <div class="icon">
                    <i
                      class="las la-certificate"
                      style="background-color: #ffe4e6; color: #cc6565"
                    />
                  </div>
                </div>
                <h4 class="mb-0 ml-1 text-clr">
                  {{ $t('dashboard.contest') }}
                </h4>
              </div>
              <b-row class="d-flex justify-content-between mx-2 mt-1">
                <span
                  >Total : <strong>{{ totalContests }}</strong>
                </span>
                <span
                  >Traités : <strong>{{ donutChartContest.series[0] }}</strong>
                </span>
                <span
                  >Non Traités / En attente :
                  <strong>{{ donutChartContest.series[1] }}</strong>
                </span>
              </b-row>
            </b-card>
          </b-col>
          <!-- users -->
          <b-col cols="12">
            <b-card>
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <div class="">
                  <div class="icon">
                    <i
                      class="las la-users"
                      style="background-color: #d4eff4; color: #4762af"
                    />
                  </div>
                </div>
                <h4 class="mb-0 ml-1 text-clr">
                  {{ $t('dashboard.users') }}
                </h4>
              </div>
              <b-row class="d-flex justify-content-between mx-2 mt-1">
                <span
                  >Total : <strong>{{ totalUsers }}</strong>
                </span>
                <span
                  >Agents Administration :
                  <strong>{{ donutChartUsers.series[1] }}</strong>
                </span>
                <span
                  >Opérateurs :
                  <strong>{{ donutChartUsers.series[0] }}</strong>
                </span>
                <span
                  >Autres utilisateurs :
                  <strong>{{ donutChartUsers.series[2] }}</strong>
                </span>
              </b-row>
            </b-card>
          </b-col>
        </div>
      </section>
    </vue-html2pdf>

    <!-- Modal de suppression de la plainte -->
    <!--     <b-modal id="modal-delete-ticket" ref="modal-delete-ticket"
        :title="$t('complaints.complaint_list.modal_remove.title')" ok-variant="danger" cancel-variant="outline-secondary"
        modal-class="modal-danger" :ok-title="$t('complaints.complaint_list.modal_remove.button_accept')"
        :cancel-title="$t('complaints.complaint_list.modal_remove.button_cancel')" no-close-on-backdrop @ok="deleteManuel">
        <div>
          <b-card-text>
            <h5>{{ $t('complaints.complaint_list.modal_remove.text') }}</h5>
          </b-card-text>
        </div>
      </b-modal> -->
  </div>
</template>
  
  <script>
import {
  BCard,
  BCardBody,
  BCardText,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BLink,
  BModal,
  VBModal,
  BBadge,
  BTabs,
  BTab,
  BSpinner,
  VBToggle,
  BInputGroup,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import localstorageService from '@/services/localstorage/localstorage.service'
import VueApexCharts from 'vue-apexcharts'
// store modules and vuex utilities
// eslint-disable-next-line import/no-cycle
import etablissementsStoreModule from '@/store/etablissements'
import statusStoreModule from '@/store/status'
import plaintesStoreModules from '@/store/plaintes'
import dashboardStatsStoreModules from '@/store/dashboard-stats'
import VueHtml2pdf from 'vue-html2pdf'
import statuses from '@/views/apps/formulaires/requests_states.js'
import DateRangeWidget from '@/components/DateRangeWidget.vue'

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  // unregisterStoreModule,
} from '@/helpers/vuex-utils'

import utilsService from '@/services/utils/utils.service'

import PaginationComponent from '@/components/PaginationComponent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import secteursStoreModule from '@/store/secteurs'
import typeDemamdesStoreModule from '@/store/type-demandes'
import formulairesStoreModule from '@/store/formulaire'

export default {
  components: {
    // ProductsListFilters,
    // UserListAddNew,

    BCard,
    BCardBody,
    BRow,
    BCardText,
    // BCollapse,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BInputGroup,
    BBadge,
    BSpinner,
    BModal,
    BTabs,
    BTab,
    vSelect,
    PaginationComponent,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
    DateRangeWidget,
    VueApexCharts,
    VueHtml2pdf,
  },

  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    const {
      currentUserId,
      isAdmin,
      isMtcaAdmin,
      isMtcaAgent,
      isOtAdmin,
      isOtAgent,
      isAdministrationUser,
      isAuditor,
      isOperator,
      compileUniqueStatuses,
    } = utilsService.currentUserUtils()
    return {
      currentUserId,
      isAdmin,
      isMtcaAdmin,
      isMtcaAgent,
      isOtAdmin,
      isOtAgent,
      isAdministrationUser,
      compileUniqueStatuses,
      isAuditor,
      isOperator,
      isLoading: false,
      isLoading_requests: false,
      isFetchingSecteur: false,
      isFetchingTypeDemande: false,
      tableData: [],
      listSecteurs: [],
      statuses,
      tableRequestData: [],
      tableColumns: [
        { key: 'fullname', label: 'table_columns.fullname', sortable: true },
        { key: 'phone_number', label: 'table_columns.phone', sortable: true },
        { key: 'email', label: 'table_columns.email', sortable: true },
        {
          key: 'object',
          label: 'table_columns.object',
          sortable: true,
          thStyle: { width: '20%' },
        },
        { key: 'profile', label: 'table_columns.company', sortable: true },
        {
          key: 'statuses',
          label: 'table_columns.status',
          sortable: true,
        },
        { key: 'created_at', label: 'table_columns.date', sortable: true },
        { key: 'actions', label: 'table_columns.actions' },
      ],
      tableRequestColumns: [
        {
          key: 'created_at',
          label: 'table_columns.created_at',
          sortable: true,
        },
        {
          key: 'reference',
          label: 'table_columns.reference',
          sortable: true,
        },
        {
          key: 'sector_label',
          label: 'table_columns.sector',
          sortable: true,
        },
        {
          key: 'type_demande',
          label: 'table_columns.requests_types',
          sortable: true,
        },
        {
          key: 'identity',
          label: 'table_columns.applicants',
          sortable: true,
        },
        {
          key: 'statuses',
          label: 'table_columns.status',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'table_columns.actions',
          sortable: true,
          thStyle: { width: '5%' },
        },
      ],
      paginationData: {
        currentPage: 1,
        total: 0,
        per_page: 10,
        metaData: { from: 0, to: 0 },
      },
      paginationRequestData: {
        currentPage: 1,
        total: 0,
        per_page: 10,
        metaData: { from: 0, to: 0 },
      },
      totalUsers: 0,
      totalClaims: 0,
      totalContests: 0,
      totalRequests: 0,

      donutChartRequest: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '12px',
            fontFamily: 'Montserrat',
          },
          colors: ['#3a8e84', '#C0C0C0'],
          dataLabels: {
            enabled: true,
            fontSize: '15px',
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '10px',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '15px',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '15px',
                    label: 'Totals',
                    fontWeight: 'bold',
                    value: this.totalContests,
                    /*  formatter(val) {
                         return `${parseInt(val)}`
                       }, */
                  },
                },
              },
            },
          },
          labels: ['Traitées', 'Non traitées'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '10px',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1rem',
                          color: 'black',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      donutChartClaims: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '12px',
            fontFamily: 'Montserrat',
          },
          colors: ['#ff9e43', '#C0C0C0'],
          dataLabels: {
            enabled: true,
            fontSize: '15px',
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '10px',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '15px',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '15px',
                    label: 'Totals',
                    fontWeight: 'bold',
                    value: this.totalClaims,
                    /*                     formatter() {
                                            return this.totalClaims
                                          }, */
                  },
                },
              },
            },
          },
          labels: ['Traitées', 'Non traitées'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '10px',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1rem',
                          color: 'black',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      donutChartContest: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '12px',
            fontFamily: 'Montserrat',
          },
          colors: ['#f3706a', '#C0C0C0'],
          dataLabels: {
            enabled: true,
            fontSize: '15px',
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '10px',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '15px',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '15px',
                    label: 'Totals',
                    fontWeight: 'bold',
                    value: this.totalContests,
                    /*  formatter(val) {
                         return `${parseInt(val)}`
                       }, */
                  },
                },
              },
            },
          },
          labels: ['Actifs', 'Inactifs'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '10px',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1rem',
                          color: 'black',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
      donutChartUsers: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '12px',
            fontFamily: 'Montserrat',
          },
          colors: ['#3a8e84', '#f3706a', '#ff9e43'],
          dataLabels: {
            enabled: true,
            fontSize: '15px',
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '10px',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '15px',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '15px',
                    label: 'Totals',
                    fontWeight: 'bold',
                    value: this.totalUsers,
                    /*  formatter() {
                         return this.totalUsers
                       }, */
                  },
                },
              },
            },
          },
          labels: ['Opérateurs', 'Agents Administration', 'Autres'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '10px',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1rem',
                          color: 'black',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },

      plainteId: null,
      filteredOptions: [],
      listProfils: [],
      listStatus: [],
      listTypesDemandes: [],
      listMetiers: [],
      listObjets: [
        { id: '1', title: 'Retard du moyen de transport' },
        { id: '2', title: 'Service client irrespectueux' },
        { id: '3', title: 'Problèmes de réservation' },
        { id: '4', title: 'Guide mal informé' },
        { id: '5', title: 'Itinéraire de visite insatisfaisant' },
        { id: '6', title: 'Guide irrespectueux ou désintéressé.' },
        { id: '7', title: 'Réservation annulée sans préavis' },
        { id: '8', title: 'Problèmes de facturation ou de paiement' },
        { id: '9', title: 'Bruit excessif' },
        { id: '10', title: 'Service lent ou inattentif' },
        { id: '11', title: 'Commande incorrecte' },
        { id: '12', title: "Problèmes d'hygiène ou de salubrité" },
      ],
      filters: {
        name: null,
        sector: null,
        object: null,
        message: null,
        profile_id: null,
        status: null,
        periode: null,
      },
      isDownloading: false,
      filterBy: {
        secteur_id: null,
        type_demande_id: null,
        identity: null,
        periode: null,
        reference: null,
        code_demande: null,
        statuses: [], // "on_hold"
      },
    }
  },
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStoreModule },
      { path: 'status', module: statusStoreModule },
      { path: 'plaintes', module: plaintesStoreModules },
      { path: 'dashboard-stats', module: dashboardStatsStoreModules },
      { path: 'secteurs', module: secteursStoreModule },
      { path: 'type-demandes', module: typeDemamdesStoreModule },
      { path: 'formulaires', module: formulairesStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)
    return {
      requiredStoreModules,
    }
  },
  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        await this.getListDemandes()
        await this.loadProfils()
        await this.loadMetiers()
        if (this.isAdmin || this.isMtcaAdmin || this.isMtcaAgent) {
          await this.loadStatus()
          await this.searchPlainte()
        }
        this.statuses = this.compileUniqueStatuses
      },
    },
    per_page: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.tableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1)
          )
        }
        if (this.tableRequestData && this.tableRequestData[val - 1]) {
          this.tableRequestData = this.customCloneDeep(
            this.tableRequestData.slice(0, val - 1)
          )
        }
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.searchPlainte()
      },
    },
    'paginationData.per_page': {
      handler(val, old) {
        this.searchPlainte()
      },
    },
    'paginationRequestData.currentPage': {
      handler(val, old) {
        this.getListDemandes()
      },
    },
    'paginationRequestData.per_page': {
      handler(val, old) {
        this.getListDemandes()
      },
    },
  },

  mounted() {
    this.fetchSecteurs()
    this.fetchTypesDemandes()
    this.$nextTick(() => {
      this.getClaimscount()
      this.getContestscount()
      this.getUserscount()
      this.getRequestscount()
    })
  },

  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },

  methods: {
    ...mapActions('etablissements', {
      action_searchEtablissement: 'searchEtablissement',
      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    ...mapActions('secteurs', {
      action_fetchSecteurs: 'fetchSecteurs',
    }),
    ...mapActions('type-demandes', {
      action_fetchTypesDemandes: 'fetchTypeDemandes',
    }),
    ...mapActions('status', {
      action_fetchStatus: 'fetchStatus',
    }),
    ...mapActions('plaintes', {
      action_searchPlainte: 'searchPlainte',
      action_deletePlainte: 'deletePlainte',
    }),
    ...mapActions('dashboard-stats', {
      action_getClaimscount: 'getClaimscount',
      action_getUserscount: 'getUserscount',
      action_getContestscount: 'getContestscount',
      action_getRequestscount: 'getRequestscount',
    }),
    ...mapActions('formulaires', {
      action_searchFormulaires: 'searchFormulaires',
    }),
    // ------------------------------------------------------------------
    // Chargement des profils
    async loadProfils() {
      try {
        const response = await this.action_searchEtablissement()
        if (response.status === 200) {
          this.listProfils = response.data.data
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des profils', error)
      }
    },
    // Chargement des métiers
    async loadMetiers() {
      try {
        const response = await this.action_getListTypesEtablissement()
        if (response.status === 200) {
          this.listMetiers = response.data.data
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des métiers', error)
      }
    },
    fetchSecteurs() {
      this.isFetchingSecteur = true
      this.action_fetchSecteurs()
        .then((response) => {
          this.isFetchingSecteur = false
          this.listSecteurs = response.data.data
        })
        .catch((error) => {
          this.isFetchingSecteur = false
        })
    },
    fetchTypesDemandes() {
      this.isFetchingTypeDemande = true
      this.action_fetchTypesDemandes()
        .then((response) => {
          this.isFetchingTypeDemande = false
          this.listTypesDemandes = response.data.data
        })
        .catch((error) => {
          this.isFetchingTypeDemande = false
        })
    },
    getListDemandes() {
      this.isLoading_requests = true
      const params = {
        ...this.filterBy,
        page: this.paginationRequestData.currentPage - 1,
        per_page: this.paginationRequestData.per_page,
      }

      if (this.isOtAdmin || this.isOtAdmin) {
        // an OT profil
        params.except_statuses = [
          'on_hold',
          'treatment_start',
          'form_control',
          'rejected_request',
        ]
      }
      if (this.isMtcaAgent) {
        // an agent
        params.statuses = [
          'on_hold', 'treatment_start', 'form_control',
          'rejected_request', 'accepted_request',

          'user_notification_unfavourable_agreement', 
          'user_notification_favourable_agreement_with_conditions',
          'user_notification_favourable_agreement_minister',
          'user_notification_unfavourable_agreement_minister',
          'user_notification_favourable_agreement', 'opening', 'corrections',
          'technical_control_recommendation_check', 'rejection_integration_recommendations',
          'validation_integration_recommendations', 'rejection_opening', 'validation_opening',

          'user_notification_final_classification_fail', 
          'user_notification_final_classification_success',

          'in_classification_process', 'classification_repport_check',
          'user_notification_final_reclassification_fail', 
          'user_notification_final_reclassification_success',
          'reject_succursale_opening', 'accept_succursale_opening',
          'procedure_completion_by_applicant',
        ]
      }

      console.log('getListDemandes params::: 🟢🟢 🔴', params)
      this.action_searchFormulaires(params)
        .then((res) => {
          if (res.status === 200) {
            this.isLoading_requests = false
            this.tableRequestData = []
            console.log('res.data.data::: 🟢🟢🔴🔴', res.data.data)
            res.data.data.forEach((item) => {
              console.log('item.data.code_demande::: ', item.data.code_demande)
              const code_demande = item.data.code_demande
              console.log('code_demande::: ', code_demande)
              let curr_step = null
              let selectedForm =
                utilsService.getFormulaireByCodeDemande(code_demande)
              if (selectedForm) {
                if((item.statuses && item.statuses[0] && item.statuses[0].name) && (selectedForm.process_steps && selectedForm.process_steps)){
              curr_step = this.get_current_step(
                selectedForm.process_steps && selectedForm.process_steps,
                item.statuses && item.statuses[0].name,
              )
              }else
              {
                curr_step = this.get_current_step(
                  null,
                null,
              ) 
              }
              }
              const curr_auto_eval = item.auto_evaluation
              const curr_audit = item.audit
              const curr_type_demande_code = item.type_demande && item.type_demande.code
              const should_have_auto_eval = this.should_have_auto_eval_control(
                curr_type_demande_code
              )

              const result_can_process_to_treatment =
                this.can_process_to_treatment(
                  curr_audit,
                  should_have_auto_eval,
                  curr_auto_eval
                )

              const slug = utilsService.getSlugByCodeDemande(
                item.type_demande && item.type_demande.code
              )

              this.tableRequestData.push({
                ...item.data,
                user: item.user,
                user_id: item.user_id,
                id: item.id,
                statuses: item.statuses && item.statuses,
                created_at: item.created_at,
                updated_at: item.updated_at,
                duration: item.type_demande && item.type_demande.duration,
                type_demande: item.type_demande && item.type_demande,
                can_process_to_treatment: result_can_process_to_treatment,
                should_have_auto_eval,
                auto_evaluation: item.auto_evaluation,
                audit: item.audit,
                reference: item.reference,
                code_demande: item.type_demande && item.type_demande.code,
                slug,
                curr_step,
                process_steps:
                  (selectedForm && selectedForm.process_steps) || [],
                sector_label: (selectedForm && selectedForm.sector_label) || '',
              })
            })
            // --------------------------------------------------------------
            this.paginationRequestData.total = res.data.meta.total || 0
            if (this.paginationRequestData.metaData.from === 0) {
              this.paginationRequestData.metaData.from += 1
            } else {
              this.paginationRequestData.metaData.from =
                this.paginationRequestData.per_page *
                  this.paginationRequestData.currentPage -
                this.paginationRequestData.per_page
            }
            this.paginationRequestData.metaData.to =
              this.paginationRequestData.per_page *
                this.paginationRequestData.currentPage -
              this.paginationRequestData.per_page +
              res.data.meta.total
            // --------------------------------------------------------------
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoading_requests = false
        })
    },
    // Chargement des status
    async loadStatus() {
      try {
        const response = await this.action_fetchStatus()
        if (response.status === 200) {
          this.listStatus = response.data
        }
      } catch (error) {
        console.log('Erreur lors du chargement de la liste des status', error)
      }
    },
    getVariantColorByInterval(number, basic_duration) {
      const duration = (basic_duration /= 24)
      if (number >= 0 && number <= (duration / 3) * 2) {
        return 'primary'
      }
      if (number > (duration / 3) * 1 && number <= duration) {
        return 'warning'
      }
      return 'danger'
    },
    show_alert(last_status) {
      if(last_status)
      return (
        last_status.name !== 'treatment_completed_successfully' &&
        last_status.name !== 'treatment_completed_failure'
      )
    },
    getClaimscount() {
      this.isLoading = true
      this.action_getClaimscount()
        .then((response) => {
          this.isLoading = false
          if (response.status == 200 && response.data) {
            this.totalClaims = 500
            this.donutChartClaims.series[0] = 400
            this.donutChartClaims.series[1] = 100
          } else {
            this.totalClaims = parseInt(response.data.total)
            this.donutChartClaims.series[0] = parseInt(response.data.treated)
            this.donutChartClaims.series[1] = parseInt(
              response.data.not_treated
            )
          }
        })
        .catch((error) => {
          //  this.isFetchingSecteur = false;
        })
    },
    getRequestscount() {
      this.isLoading = true
      this.action_getRequestscount()
        .then((response) => {
          this.isLoading = false
          if (response.status == 200 && response.data) {
            this.totalRequests = parseInt(
              response.data.on_hold +
                response.data.treatment_completed_successfully +
                response.data.treatment_completed_failure
            )

            this.donutChartRequest.series[0] = parseInt(
              response.data.treatment_completed_successfully +
                response.data.treatment_completed_failure
            )
            this.donutChartRequest.series[1] = parseInt(response.data.on_hold)
            // ------------------------------------------------------for fake data
            // this.totalRequests = 500;
            // this.donutChartRequest.series[0] = 400;
            // this.donutChartRequest.series[1] = 100;
          }
        })
        .catch((error) => {
          //  this.isFetchingSecteur = false;
        })
    },
    getUserscount() {
      this.isLoading = true
      this.action_getUserscount()
        .then((response) => {
          this.isLoading = false
          if (response.status === 200) {
            this.totalUsers = parseInt(response.data.total)
            this.donutChartUsers.series[0] = parseInt(
              response.data.extern_users
            )
            this.donutChartUsers.series[1] = parseInt(
              response.data.intern_admins
            )
            const autres_users =
              response.data.total -
              (response.data.extern_users + response.data.intern_admins)
            this.donutChartUsers.series[2] = parseInt(autres_users)
            // ------------------------------------------------------for fake data
            // this.totalUsers = 350;
            // this.donutChartUsers.series[0] = 150;
            // this.donutChartUsers.series[1] = 150;
            // this.donutChartUsers.series[2] = 50;
          }
        })
        .catch((error) => {
          console.log('error::: ', error)
          this.isLoading = false
        })
    },
    getContestscount() {
      this.isLoading = true
      this.action_getContestscount({ type: 'concours' })
        .then((response) => {
          this.isLoading = false
          if (response.status === 200 && response.data) {
            this.totalContests =
              parseInt(response.data.drafted) +
              parseInt(response.data.published)
            this.donutChartContest.series[0] = parseInt(response.data.published)
            this.donutChartContest.series[1] = parseInt(response.data.drafted)
            // ------------------------------------------------------for fake data
            // this.totalContests = 150;
            // this.donutChartContest.series[0] = 80;
            // this.donutChartContest.series[1] = 70;
          }
        })
        .catch((error) => {
          console.log('error::: ', error)
          this.isLoading = false
        })
    },
    // Recherche des plaintes
    async searchPlainte() {
      try {
        this.isLoading = true

        let periodeFormatted = null
        if (this.filters.periode && this.filters.periode !== '') {
          const periodeArray = this.filters.periode.split(' au ')
          if (periodeArray.length === 2) {
            periodeFormatted = {
              from: periodeArray[0],
              to: periodeArray[1],
            }
          } else if (periodeArray.length === 1) {
            periodeFormatted = {
              from: periodeArray[0],
              to: periodeArray[0],
            }
          }
        }

        const response = await this.action_searchPlainte({
          name: this.filters.name,
          sector: this.filters.sector,
          object: this.filters.object,
          message: this.filters.message,
          profile_id: this.filters.profile_id,
          status: this.filters.status,
          periode: periodeFormatted,
          // params for filter by pagination
          page: this.paginationData.currentPage,
          per_page: this.paginationData.per_page,
        })
        if (response.status === 200) {
          this.tableData = response.data.data
          console.log(this.tableData)
          this.isLoading = false
          // --------------------------------------------------------------
          this.paginationData.total = response.data.meta.total || 0
          if (this.paginationData.metaData.from === 0) {
            this.paginationData.metaData.from += 1
          } else {
            this.paginationData.metaData.from =
              this.paginationData.per_page * this.paginationData.currentPage -
              this.paginationData.per_page
          }
          this.paginationData.metaData.to =
            this.paginationData.per_page * this.paginationData.currentPage -
            this.paginationData.per_page +
            response.data.meta.total
          // --------------------------------------------------------------
        }
      } catch (error) {
        this.isLoading = false
        console.error(
          'Erreur lors du chargement des données de la recherche de plainte',
          error
        )
      }
    },
    // Changement de page
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage
      this.paginationData.per_page = val.per_page
    },
    onPaginationRequestChange(val) {
      this.paginationRequestData.currentPage = val.currentPage
      this.paginationRequestData.per_page = val.per_page
    },
    // Redirection vers la page de détails
    goToDetails(id) {
      localstorageService.setPlainteId(id)
      this.$router.push({ name: 'plainte-details', params: { id } })
    },
    // Demande de consentement
    wouldDelete(id) {
      this.plainteId = id
      this.$refs['modal-delete-ticket'].show()
    },
    // Suppression d'une plainte
    async deleteManuel() {
      this.isLoading = true
      try {
        const response = await this.action_deletePlainte(this.plainteId)
        if (response.status === 204) {
          this.isLoading = false
          this.$router.replace({ name: 'plainte-list' })
          await this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Suppresion réussie',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        }
      } catch (error) {
        this.isLoading = false
        console.log('Erreur lors de la plainte', error)
        await this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Une erreur est survenue',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: '',
          },
        })
      }
    },
    getTypesDemandes(secteur_id) {
      if (secteur_id) {
        this.listSecteurs.map((secteur) => {
          if (secteur.id === secteur_id) {
            this.listTypesDemandes = secteur.type_demandes
          }
        })
      }
    },

    async exportation_stat() {
      if (this.isDownloading) return
      this.isDownloading = true
      await this.$refs.printableStat.generatePdf()
      this.$refs.printableStat.$once('hasDownloaded', () => {
        this.isDownloading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Document téléchargé avec succès',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        })
        this.forPrint = false
      })
    },
  },
}
</script>
  <style lang="scss">
.invoice-preview-wrapper {
  .row.invoice-preview {
    .col-md-8 {
      max-width: 100%;
      flex-grow: 1;
    }

    .invoice-preview-card {
      .card-body:nth-of-type(2) {
        .row {
          > .col-12 {
            max-width: 50% !important;
          }

          .col-12:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  // Action Right Col
  .invoice-actions {
    display: none;
  }
}

.stat {
  font-size: 30px;
  font-weight: 800;
}

.icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon i {
  padding: 10px;
  font-size: 25px;
  border-radius: 5px;
  font-weight: bold;
}

.title {
  font-size: 13px;
  font-weight: 700;
}

.stat-text {
  margin-left: 15px;
}

.filter {
  background: linear-gradient(to right, #11645a, #508d86);
  border-radius: 5px;
}

.colr {
  color: white;
}

.pdd {
  padding: 1px !important;
}

.text-clr {
  color: black;
}

.weight-800 {
  font-weight: 800;
  font-size: 30px;
  margin-bottom: 0px;
}

.icon {
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}

.title {
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 700;
}

.hr {
  color: #11645a;
  height: 5px;
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.nav-pills .nav-link {
  color: white !important;
}

.nav-pills .nav-link.active {
  color: #000000 !important;
}
</style>
  